body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  background: #343e4b;

  &.modal-open {
    overflow: hidden;
  }
}

h2 {
  font-weight: 400;
}

::-webkit-scrollbar { 
  display: none; 
}